<template>
  <PageCard
    pageName="FinTech Market Reports"
    :iconClass="['cuis-chart-pie', 'card-icon-font']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <FeatureDetailsCard
                title="FinTech Market Reports"
                description1="Quickly assess market share or client firmographics by vendor or vendor-product."
                description2="Other vendors offering your technology may not be your true competition – if they attract different FI segments. Leverage the analysis for strategy development, competitive intel, segment analysis, white space identification or marketing ammo."
                button1Text="See Use Cases"
                :button1Link="usecaseLink"
                :galleryImages="VC_SOL_FIN_M"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

export default {
  name: 'AboutMarketReports',
  components: {
    PageCard,
    FeatureDetailsCard
  },
  data() {
    return {
      VC_SOL_FIN_M: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M4.png'
      ]
    }
  },
  created() {
    document.title = 'FI Vertical Strategy'
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['isFiMenu']),
    usecaseLink() {
      return this.isFiMenu ? '/technology_analytics/about' : '/reports/about'
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
